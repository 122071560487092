@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  min-height: 100%;
  min-width: 100%;
  background-color: #4682b4;
}

.geocoder-control-suggestions .geocoder-control-suggestion {
  overflow-wrap: break-word;
  word-break: break-all;
  overflow: visible;
  white-space: break-spaces;
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
}

.geocoder-control-suggestion:hover {
  background-color: #f2f2f2;
}
